import { A } from "@solidjs/router";
import { Accessor, For, Setter, Show, createSignal, onMount } from "solid-js";
import { browseAllIcon } from "~/assets/assets";
import ArrowDown from "~/assets/svg_icons/arrow_down";
import { CategoryData } from "~/server/types/category";
import { formatGroup, getCategorySlugFromCategoryName } from "~/utils/common";
import { classNames } from "~/utils/etc";
import { useOutsideClick } from "~/utils/hooks/use_outside_click";
import { Divider } from "~/widgets/divider";
import { PhosphorIcon } from "~/widgets/icons";
import { Spacer } from "~/widgets/spacer";

function isString(x: any) {
  return Object.prototype.toString.call(x) === "[object String]";
}

export function GroupsLinkPanel(props: {
  groups: CategoryData[] | string[];
  link: string;
  handleOnClick: () => void;
}) {
  return (
    <div class="sm:no-scrollbar grid max-h-[480px] grid-cols-1 flex-col overflow-y-scroll px-4 md:grid-cols-2">
      <div class="mt-4 md:hidden"></div>
      <For each={props.groups}>
        {(group: string | CategoryData) => {
          const href = isString(group)
            ? `${props.link}${formatGroup(group as string)}`
            : `${props.link}${getCategorySlugFromCategoryName((group as CategoryData).name)}`;
          return (
            <A
              href={href}
              onclick={(event) => {
                props.handleOnClick();
              }}
            >
              <div class="flex flex-row items-center justify-between rounded-lg p-2 transition-colors duration-300 hover:bg-baseTertiaryMedium">
                <div class="text-bold text-textDark lg:text-mediumBold">
                  <Show when={!isString(group)}>
                    {(group as CategoryData).metadata.title}
                  </Show>
                  <Show when={isString(group)}>{group as string}</Show>
                </div>
              </div>
            </A>
          );
        }}
      </For>
    </div>
  );
}

export function CategoryDialog(props: {
  setShowCategoryList: Setter<boolean>;
  categories: CategoryData[];
  occasions?: string[];
  recipients?: string[];
}) {
  const [activeTab, setActiveTab] = createSignal("categories");
  let categoryDialogRef: HTMLDivElement;

  onMount(() => {
    if (categoryDialogRef) {
      useOutsideClick(
        () => categoryDialogRef,
        () => {
          props.setShowCategoryList(false);
        }
      );
    }
  });

  return (
    <div class="flex flex-row">
      <div
        class={`fixed inset-0 z-50 w-full bg-baseDark  opacity-40 transition-all duration-300 md:top-20 md:hidden`}
      />
      <div
        ref={(el) => (categoryDialogRef = el)}
        class="fixed left-0 right-0 top-14 z-50 mx-4 my-3 flex justify-start md:mx-auto lg:absolute lg:top-24 lg:mx-auto lg:w-[1107px]"
      >
        <div
          class=" no-scrollbar w-full overflow-y-scroll rounded-xl border border-baseTertiaryDark bg-baseTertiaryLight py-3 lg:w-auto"
          style={{ "box-shadow": "0px 2px 20px 0px rgba(0, 0, 0, 0.1)" }}
        >
          <div class="w-full lg:hidden">
            <MobileCategoriesDialog />
          </div>
          <div class="hidden lg:flex">
            <DesktopCategoriesDialog />
          </div>
        </div>
      </div>
    </div>
  );

  function MobileCategoriesDialog() {
    return (
      <>
        <div class="mx-4 mt-2 flex items-center justify-between">
          <div class="flex items-center justify-start">
            <div class="p-[6px] lg:hidden">
              <PhosphorIcon
                name="squares-four"
                size="fill"
                fontSize={20}
                class="text-baseSecondaryDark"
              ></PhosphorIcon>
            </div>
            <div class=" pb-2 text-h3 text-textDark lg:text-medium lg:text-baseSecondaryMedium">
              Browse
            </div>
          </div>
          <div
            class="cursor-pointer p-2 lg:hidden"
            onClick={() => {
              props.setShowCategoryList(false);
            }}
          >
            <PhosphorIcon name="x" size="light" fontSize={20}></PhosphorIcon>
          </div>
        </div>

        {/* <NavBar/> */}

        <Show when={activeTab() === "categories"}>
          <div class="mt-0">
            <GroupsLinkPanel
              groups={props.categories}
              link={"/shop-by-category/"}
              handleOnClick={() => {
                props.setShowCategoryList(false);
              }}
            />
          </div>
        </Show>
      </>
    );
  }

  function DesktopCategoriesDialog() {
    return (
      <Show when={props.categories && props.categories.length > 0}>
        <div class="ml-8 min-w-[350px] border-baseTertiaryMedium">
          <div class="mb-2 mt-2 flex items-center justify-between px-4">
            <div class="flex items-center justify-start">
              <div class="p-[6px] lg:hidden">
                <PhosphorIcon
                  name="squares-four"
                  size="fill"
                  fontSize={20}
                  class="text-baseSecondaryDark"
                ></PhosphorIcon>
              </div>
              <div class=" pb-2 text-h3 text-textDark lg:text-medium lg:text-baseSecondaryMedium">
                Browse by category
              </div>
            </div>
            <div
              class="cursor-pointer p-2 lg:hidden"
              onClick={() => {
                props.setShowCategoryList(false);
              }}
            >
              <PhosphorIcon name="x" size="light" fontSize={20}></PhosphorIcon>
            </div>
          </div>

          <div class="pb-2 lg:hidden">
            <Divider></Divider>
          </div>

          <GroupsLinkPanel
            groups={props.categories}
            link={"/shop-by-category/"}
            handleOnClick={() => {
              props.setShowCategoryList(false);
            }}
          />
        </div>
      </Show>
    );
  }

  function NavBar() {
    return (
      <nav class="no-scrollbar flex justify-start gap-x-1 overflow-x-scroll border-b border-solid border-baseTertiaryDark p-0 pt-3">
        <button
          class={`${
            activeTab() === "categories"
              ? "border-baseDark bg-baseTertiaryMedium"
              : "text-gray-500"
          } cursor-pointer whitespace-nowrap rounded-lg border border-baseTertiaryDark px-4 py-3 text-bold`}
          onClick={() => setActiveTab("categories")}
        >
          By category
        </button>
        <button
          class={`${
            activeTab() === "occasions"
              ? "border-baseDark bg-baseTertiaryMedium"
              : "text-gray-500"
          } cursor-pointer whitespace-nowrap rounded-lg border border-baseTertiaryDark px-4 py-3 text-bold`}
          onClick={() => setActiveTab("occasions")}
        >
          By occasions
        </button>
        <button
          class={`${
            activeTab() === "recipients"
              ? "border-baseDark bg-baseTertiaryMedium"
              : "text-gray-500"
          } cursor-pointer whitespace-nowrap rounded-lg border border-baseTertiaryDark px-4 py-3 text-bold`}
          onClick={() => setActiveTab("recipients")}
        >
          By recipients
        </button>
      </nav>
    );
  }
}

function buildRow(categories: CategoryData[]) {
  return (
    <For each={categories}>
      {function (category, index) {
        return [
          <CategorySingleCard category={category} />,
          index() < categories.length - 1 ? (
            <div class="w-[1px] bg-baseTertiaryMedium"></div>
          ) : null,
        ];
      }}
    </For>
  );
}

function CategorySingleCard(props: { category: CategoryData }) {
  return (
    <div class="flex-1">
      <A
        href={
          "/shop-by-category/" +
          getCategorySlugFromCategoryName(props.category.name)
        }
      >
        <div class="flex flex-col items-center justify-center px-3 py-4">
          <div class="flex h-8 w-8 flex-col  items-center justify-center ">
            <img src={props.category.metadata.thumbnailUrl} alt="category" />
          </div>
          <Spacer height={6} />
          <span class="line-clamp-1 text-center   text-f12Bold text-basePrimaryDark ">
            {props.category.metadata.title}
          </span>
        </div>
      </A>
    </div>
  );
}

export function CategoryGridCard(props: {
  categories: CategoryData[];
  occasions?: string[];
  recipients?: string[];
}) {
  const [showCategoryList, setShowCategoryList] = createSignal(false);

  let grid: HTMLInputElement | undefined;
  const [showGrid, setShowGrid] = createSignal(true);

  let categories = props.categories.slice(
    0,
    Math.min(9, props.categories.length)
  );

  if (categories.length % 3 !== 0) {
    categories = categories.slice(1, (categories.length / 3) * 3);
  }

  let categoryColumnsData: CategoryData[][] = [];
  for (let i = 0; i < categories.length; i += 3) {
    categoryColumnsData.push(categories.slice(i, i + 3));
  }

  return (
    <div ref={grid} class="rounded-2xl border">
      <Show
        when={showGrid()}
        fallback={
          <div class="bg-gray-300">
            <div class="flex flex-row items-center px-4">
              <div class="text-nowrap text-bold text-basePrimaryDark">
                Categories
              </div>
              <div class="ml-4 h-[1px] w-full bg-baseTertiaryMedium"></div>
            </div>
          </div>
        }
      >
        <div style={{ "box-shadow": "0px 2px 11px 0px #0000000D" }}>
          <For each={categoryColumnsData}>
            {(categories, index) => [
              <div class="flex flex-row">{buildRow(categories)}</div>,
              index() < categoryColumnsData.length - 1 ? (
                <div class="h-[1px] w-full bg-baseTertiaryMedium"></div>
              ) : null,
            ]}
          </For>
          <div
            class="flex items-center justify-center gap-1.5 border-t border-baseTertiaryMedium py-4 text-mediumBold "
            onclick={() => {
              setShowCategoryList(!showCategoryList());
            }}
          >
            <img src={browseAllIcon} alt="" />
            <p>Browse all categories</p>
            <ArrowDown />
          </div>
        </div>
      </Show>
      <Show when={showCategoryList()}>
        <CategoryDialog
          setShowCategoryList={setShowCategoryList}
          categories={props.categories}
          occasions={props.occasions}
          recipients={props.recipients}
        />
      </Show>
    </div>
  );
}

export function CategoryStrip(props: {
  categories: CategoryData[];
  selectedCategory?: string;
  scroll: boolean;
  border: boolean;
  occasions?: string[];
  recipients?: string[];
  maxCategoriesOnStrip?: number;
}) {
  const [showCategoryList, setShowCategoryList] = createSignal(false);

  return (
    <div class="relative z-10 ml-auto mr-auto  w-full border-b sm:border-b-0 ">
      <div class="flex justify-center bg-baseTertiaryLight">
        <div
          class="w-full lg:w-[1136px]"
          classList={{
            "rounded-[20px] p-2 bg-baseTertiaryMedium": props.border,
            // "rounded-[20px] p-2 bg-[#F6F7FA]": props.border,
          }}
        >
          <div
            class={classNames(
              "no-scrollbar flex w-full flex-row items-center  justify-between overflow-x-scroll bg-baseTertiaryLight px-5  "
            )}
            classList={{
              "justify-between": !props.scroll,
              "no-scrollbar overflow-x-scroll justify-start": props.scroll,
              "rounded-xl border border-baseTertiaryDark ": props.border,
            }}
            style={{
              "box-shadow": props.border
                ? "0px 2px 11px 0px rgba(0, 0, 0, 0.05)"
                : "",
            }}
          >
            <BrowseCard
              showCategoryList={showCategoryList}
              setShowCategoryList={setShowCategoryList}
            />

            <div class="px-4">
              <div class="h-[52px] w-[2px] bg-baseTertiaryMedium"></div>
            </div>
            <For each={props.categories.slice(0, props.maxCategoriesOnStrip)}>
              {(category) => (
                <div class="px-1">
                  <A
                    href={
                      "/shop-by-category/" +
                      getCategorySlugFromCategoryName(category.name)
                    }
                  >
                    <div
                      class="flex flex-col content-center  items-center justify-center border-b-2  px-3 py-[10px] transition-all duration-200 hover:border-basePrimaryDark "
                      classList={{
                        "border-basePrimaryDark":
                          props.selectedCategory?.toUpperCase() ===
                          category.name.toUpperCase(),
                        "border-transparent":
                          props.selectedCategory?.toUpperCase() !==
                          category.name.toUpperCase(),
                      }}
                    >
                      <img
                        src={category.metadata.thumbnailUrl}
                        alt="category"
                        style={{ "object-fit": "contain" }}
                        classList={{
                          "h-8 w-8": props.scroll,
                          "h-10 w-10": !props.scroll,
                        }}
                      />
                      <Spacer height={4} />
                      <span class="text-nowrap text-center text-subtitleSmall text-basePrimaryDark">
                        {category.metadata.title}
                      </span>
                    </div>
                  </A>
                </div>
              )}
            </For>
          </div>
        </div>
      </div>
      <Show when={showCategoryList()}>
        <CategoryDialog
          setShowCategoryList={setShowCategoryList}
          categories={props.categories}
          occasions={props.occasions}
          recipients={props.recipients}
        />
      </Show>
    </div>
  );
}

function BrowseCard(props: {
  setShowCategoryList: Setter<boolean>;
  showCategoryList: Accessor<boolean>;
}) {
  return (
    <div
      class="flex cursor-pointer flex-col content-center items-center justify-center"
      onclick={() => {
        props.setShowCategoryList(!props.showCategoryList());
      }}
    >
      <div class="h-10 w-10  p-2">
        <PhosphorIcon
          name="squares-four"
          size="fill"
          fontSize={22}
          class="text-baseSecondaryDark"
        ></PhosphorIcon>
      </div>
      <div class="flex flex-row items-center  ">
        <div class="text-mediumBold text-basePrimaryDark">Browse</div>
        <Spacer width={2} />
        <PhosphorIcon
          name={props.showCategoryList() ? "caret-up" : "caret-down"}
          fontSize={15}
          size="bold"
          class="text-baseSecondaryDark"
        ></PhosphorIcon>
      </div>
    </div>
  );
}
